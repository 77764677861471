import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Button from "../../../components/custom-widgets/button";
import MobileOnlineBanking from "../../../components/mobile-online-banking/mobile-online-banking";
import FaqAccordion from "../../../components/faq/faq-accordion";
import Icon from "../../../components/custom-widgets/icon";
import ContactInfo from "../../../components/contact-info/contact-info";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

// Helpers
import getSortedFaqDataByCategory from "../../../helpers/getSortedFaqDataByCategory";

const TreasuryExpressRegistration = () => {
  const title = "Treasury Express Mobile App Registration";
  const description = "Learn how to register your WaFd Bank Treasury Express App";
  const imgData = useStaticQuery(graphql`
    query {
      treasuryExpressApp1: file(relativePath: { eq: "treasury-express-transfer-screen.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      treasuryExpressApp2: file(relativePath: { eq: "treasury-express-transfer-steps-screen.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Treasury Management Registration"]);

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services/registration"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/treasury-management-services",
      title: "Treasury Management"
    },
    {
      id: 3,
      active: true,
      title: "Treasury Express Mobile App Registration"
    }
  ];

  const mobileOnlineBankingData = {
    content: {
      imgColClass: "col-sm-12 col-md-8 col-lg-6",
      listColClass: "col-sm-12 col-md-4 col-lg-6",
      heading: "Use the app to:",
      headingClass: "text-success",
      hasTwoImages: true,
      appleStoreUrl: "https://apps.apple.com/us/app/wafd-treasury/id1343834928",
      googlePlayStoreUrl: "https://play.google.com/store/apps/details?id=com.obs.android.olm.washfed.prod&hl=en_US",
      hasList: true,
      listData: {
        items: [
          {
            id: 1,
            text: "View account activity and make transfers"
          },
          {
            id: 2,
            text: "Submit ACH & wire payments"
          },
          {
            id: 3,
            text: "Approve ACH, wire, & account transfers"
          },
          {
            id: 4,
            text: "Access bill pay"
          },
          {
            id: 5,
            text: "Make a mobile deposit"
          },
          {
            id: 6,
            text: "Manage user access"
          }
        ]
      },
      primaryImgData: {
        imgVariable: imgData.treasuryExpressApp1.childImageSharp.gatsbyImageData,
        altText: "WAFD Treasury Express App Recent Transfers Screen."
      },
      secondaryImgData: {
        imgVariable: imgData.treasuryExpressApp2.childImageSharp.gatsbyImageData,
        altText: "WAFD Treasury Express App Transfer Funds Screen."
      }
    }
  };

  const faqAccordionData = {
    id: "treasury-management-registration-faq-section",
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Treasury Express Mobile App</h1>
        <h3>Download the Treasury Mobile app to access Treasury Express from your phone.</h3>
        <Button
          id="treasury-express-cta"
          url="/commercial-banking/treasury-management-services/express"
          text="Learn More About Treasury Express"
          showIcon={false}
        />
      </section>

      <MobileOnlineBanking {...mobileOnlineBankingData} />
      <section className="container pb-0">
        <h2>How do I register my Treasury Express Mobile App?</h2>
        <h3>
          Once you download the Treasury Mobile app, the app will request your Activation Key. The one-time registration
          process is simple and straight forward. The activation process consists of four steps:
        </h3>

        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 card border-0">
            <div className="card-body p-0">
              <h3 className="text-success font-weight-bold">Step 1:</h3>
              <p>Enter your user specific Software Activation Key (generates in desktop Treasury Express).</p>
              <p>
                <Link
                  to="#access-activation-key-section"
                  id="access-activation-key-section-anchor-link"
                  className="text-decoration-none"
                >
                  How to access your activation key
                  <Icon name="arrow-down" class="ml-2 mt-1" />
                </Link>
              </p>
            </div>
            <div className="card-footer border-0 bg-white p-0">
              <StaticImage
                src="../../../images/treasury-express-registration-activation.png"
                alt="Treasury Express Mobile App Activate Key Screen."
                quality="100"
                placeholder="blurred"
                className="mb-3 mb-md-0"
                style={{ maxWidth: "300px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 card border-0">
            <div className="card-body p-0">
              <h3 className="text-success font-weight-bold">Step 2:</h3>
              <p>
                Create your PIN. This PIN will be used to login to the App each time. PIN Requirements: 6-12 Characters,
                at least one letter and one number.
              </p>
            </div>
            <div className="card-footer border-0 bg-white p-0">
              <StaticImage
                src="../../../images/treasury-express-registration-pin.png"
                alt="Treasury Express Mobile App create your pin screen."
                quality="100"
                placeholder="blurred"
                className="mb-3 mb-md-0"
                style={{ maxWidth: "300px" }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3 mb-md-0 card border-0">
            <div className="card-body p-0">
              <h3 className="text-success font-weight-bold">Step 3:</h3>
              <p>Select how you would like to receive your one-time verification code.</p>
            </div>
            <div className="card-footer border-0 bg-white p-0">
              <StaticImage
                src="../../../images/treasury-express-registration-otp.png"
                alt="Treasury Express Mobile App Enter OTP screen."
                quality="100"
                placeholder="blurred"
                className="mb-3 mb-md-0"
                style={{ maxWidth: "300px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 card border-0">
            <div className="card-body p-0">
              <h3 className="text-success font-weight-bold">Step 4:</h3>
              <p>Enter the one-time verification code.</p>
            </div>
            <div className="card-footer border-0 bg-white p-0">
              <StaticImage
                src="../../../images/treasury-express-registration-verification.png"
                alt="Treasury Express Mobile App Registration Screen."
                quality="100"
                placeholder="blurred"
                className="mb-3 mb-md-0"
                style={{ maxWidth: "300px" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container pb-0" id="access-activation-key-section">
        <div className="row">
          <div className="col-md-6">
            <h3 className="text-success font-weight-bold">Accessing the Activation Key</h3>
            <p>
              <strong>Software Activation Key</strong>
            </p>
            <p>
              The activation key is used during the enrollment process and anytime a password reset request is
              submitted. The activation key is user specific and can be located in the <strong>Edit My Profile</strong>{" "}
              section of Treasury Express.
            </p>
            <p>The Activation Key is located in the User Information section of the page</p>
            <p>
              <strong>Note:</strong> only users with Administrative rights will be able to see activation keys. If a
              user does not have administrative rights, they will need to work with their Admin.
            </p>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../../images/treasury-express-activation.png"
              alt="Edit My Profile section of Treasury Express Mobile App."
              quality="100"
              placeholder="blurred"
              className="mb-3"
              style={{ maxWidth: "170px" }}
            />
            <StaticImage
              src="../../../images/treasury-express-activation-2.png"
              alt="Treasury Express Mobile App Activate Key."
              quality="100"
              placeholder="blurred"
            />
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <ContactInfo type="commercial"  />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default TreasuryExpressRegistration;

